﻿window.addEventListener('DOMContentLoaded', function playVideos() {
    document.querySelectorAll('.video-upload--wrapper').forEach((vw) => {
        var video = vw.querySelector('video');
        vw.style.maxHeight = video.offsetHeight;
        var btn = vw.querySelector('button');
        btn.addEventListener('click', function () {
            video.setAttribute("controls", "controls");
            btn.style.display = "none";
            video.play();
        })
    });

});

