﻿window.addEventListener('DOMContentLoaded', function zoomImages() {

let all = document.querySelectorAll(".zoomD");
let lightbox = document.getElementById("lightbox");
let body = document.getElementById("body");
console.log(lightbox);
all.forEach(element => {
    element.onclick = () => {
        console.log("img clicked");
        let clone = element.cloneNode();
        clone.className = "";
        lightbox.innerHTML = "<p class='position-absolute color-white close-lightbox'>x</p>";
        lightbox.appendChild(clone);
        lightbox.className = "show";
        body.className = "no-scroll";
    }
});
if (lightbox != undefined && lightbox != null) {
    lightbox.onclick = () => {
        lightbox.className = "";
        body.className = "avelon";
    };
}

});


