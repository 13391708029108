﻿
export default class ComponentRegistrar {
    private readonly CustomComponentMap: Map<string, CustomElementConstructor>;

    constructor() {
        this.CustomComponentMap = new Map<string, CustomElementConstructor>();
    }

    Register(name: string, component: CustomElementConstructor): void {
        this.CustomComponentMap.set(name, component)
    }

    Start(): void {
        this.CustomComponentMap.forEach((value, key) => {
            customElements.define(key, value);
        })
    }
}