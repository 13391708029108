import CustomComponent from "../../base/components/custom_component";
import DIContainer from "../../base/helpers/di-container";
import { SearchResultItem } from "../../models/search-result";

export default class SearchCard extends CustomComponent {
    private readonly translations: any;
    private readonly src: string;
    private cssClasses: string;

    private item: SearchResultItem;
    private length: number;
    public constructor() {
        super();
        this.item = JSON.parse(this.getAttribute("item")) as SearchResultItem;
        this.length = JSON.parse(this.getAttribute("length"));
        this.translations = DIContainer.GetInstance().GetSingleton("search-translations")
        this.src = this.classList.contains("length-2") ? this.item.longThumbnail as string : this.item.thumbnail as string
    }


    private fireChange() : void {
        super.render();
    }

    public BuildHtml(): string {
        return `
            <a class="h-full shadow-md custom-box relative" href=${ this.item.url}>
                ${(() => {
                    if (this.classList.contains("length-2"))
                        return `<span class="absolute z-10 inline-block px-5 py-1 text-sm font-semibold text-white rounded-full bg-gradient top-2 right-2">${this.translations.topStory}</span>`
                    else
                        return "";
                })()}
             
                <div class="search-image">
                  <picture>
                    <source media="(min-width: 768px)" srcset="${this.src}">
                    <source media="(min-width: 0px)" srcset=${this.item.thumbnail}>
                    <img  class="w-full" src="${this.src}" alt="IfItDoesntMatchAnyMedia">
                </picture>
                </div>
                <div class="search-body p-4 flex flex-col">
                    <span class="font-semibold pb-3">${this.item.nodeName}</span>
                    <span class="line-clamp-4">${this.item.pageDescription}</span>
                </div>             
            </a>
        `
    }

    public setupHTMLMappings(): void {

    }

}