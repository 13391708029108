import CustomComponent from "../../base/components/custom_component";
import EventBusEvent from "../../base/events/custom-event";
import EventBus from "../../base/events/event-bus";
import { EventTypes } from "../../base/events/event-types";
import DIContainer from "../../base/helpers/di-container";
import HTTPJSONClientManager from "../../base/helpers/http-json-client-manager";
import { SearchResult } from "../../models/search-result";

export default class Loader extends CustomComponent {

    public constructor() {
        super();
    }

    private fireChange() {
        super.render();
    }
    public BuildHtml(): string {
        return `
            <div class="loader-wrapper" >
                <span class="loader"></span>
            </div>
        `
    }

    public setupHTMLMappings(): void {
    }

}