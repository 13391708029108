﻿export default class DIContainer {
    private static _singleton: DIContainer = undefined;
    private _singletonMap: Map<string, object> = new Map<string, object>();;
    private constructor() { }

    public static GetInstance(): DIContainer {
        if (this._singleton === undefined) {
            this._singleton = new DIContainer();
        }
        return this._singleton;
    }

    public GetSingleton<T>(type: string | any): T {
        var identifier = "";
        if (typeof type === 'string') {
            identifier = type;
        } else {
            identifier = type.name;
        }    
        //the as unkown is silly but required due to type safety
        return this._singletonMap.get(identifier) as unknown as T;
    }

    //public AddSingleton(instance: object) {
    //    this._singletonMap.set(instance.constructor.name, instance);
    //}
    public AddSingleton(arg1: string | object, arg2?: object) {
        if (typeof arg1 === 'string' && arg2 !== undefined) {
            this._singletonMap.set(arg1, arg2);
        } else if (typeof arg1 === 'object') {
            this._singletonMap.set(arg1.constructor.name, arg1);
        }
    }
}