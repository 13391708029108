﻿window.addEventListener('DOMContentLoaded', initializeNavActions);
function initializeNavActions() {
    navToggleSetup();
    switchNavBackground();
    setNavHeight();
}
function switchNavBackground() {
    if (document.body.scrollTop > 25 || document.documentElement.scrollTop > 25) {
        document.querySelector(".main-nav").style.background = "linear-gradient(117deg,#183060 0%,#0B162C 54%,#050A13 100%)";

    } else {
        document.querySelector(".main-nav").style.background = "transparent";
    }
}
// add event listeners to the hamburger menu buttons
function navToggleSetup() {
    Array.from(document.querySelectorAll('[data-nav-toggle]')).forEach(el => el.addEventListener('click', toggleMobileNav));
    document.addEventListener('keydown', (e) => {
        if (e.key != 'Escape')
            return;
        let nav = document.querySelector('[data-mobile-nav]');
        if (nav) {
            if (nav.classList.contains('open')) {
                toggleMobileNav();
                nav.focus();
            }

        }
    });
}

// toggle the hamburger button state (open / closed)
// toggle the hamburger menu state (open / closed)
// make body unscrollable when hamburger menu is open
function toggleMobileNav(e) {
    Array.from(document.querySelectorAll('[data-mobile-nav]')).forEach(el => {
        el.classList.toggle('open')
        const focusableElements = el.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        if (focusableElements.length > 0) {
            focusableElements[0].focus();
        }
    });

    Array.from(document.querySelectorAll('[data-nav-toggle]')).forEach(el => {
        el.classList.toggle('open');
        if (el.classList.contains('open'))
            el.ariaKeyShortcuts = 'Escape';
        else
            el.ariaKeyShortcuts = '';
    });
    document.querySelector('body').style.setProperty('--st', -(document.documentElement.scrollTop) + "px");
    document.querySelector('body').classList.toggle('no-scroll');
    //Array.from(document.querySelectorAll('[data-nav-blur]')).forEach(el => el.classList.toggle('nav-blurred'));
    //document.querySelector('[data-nav-overlay]').classList.toggle('open');

}



// hide main navigation when scrolling down
// show when scrolling back up
let scrollPosition = 0;
let hasScrolled = false;
window.addEventListener('scroll', hideOrShowNavigation);
window.addEventListener('resize', setNavHeight);

function hideOrShowNavigation(event) {
    switchNavBackground();
    if (!hasScrolled) {
        hasScrolled = true;
        return;
    }
    var menu = document.querySelector('.main-nav');
    var navHeight = menu.offsetHeight;
    var newScrollPosition = event.currentTarget.scrollY;

    if (newScrollPosition - scrollPosition < 0) {
        if (menu && menu.classList.contains('collapsed')) {
            menu.classList.toggle('collapsed');
        }
    }

    else if (newScrollPosition - scrollPosition > 0 && scrollPosition > parseInt(navHeight) * 1) {
        if (menu && !menu.classList.contains('collapsed')) {
            menu.classList.toggle('collapsed');
        }
    }
    scrollPosition = newScrollPosition;
}

function setNavHeight() {
    var r = document.querySelector(':root');
    var navigation = document.querySelector('.main-nav');
    var navheight = getComputedStyle(r).getPropertyValue('--nav-height');
    var difference = parseInt(navheight.replace('px', ''));
    var finaldiff = navigation.offsetHeight - difference;
    var extraheight = finaldiff.toString() + 'px';
    var marginnav = (difference + finaldiff).toString() + 'px';
    r.style.setProperty('--nav-height-extra', marginnav);
}