﻿import EventBusEvent from "./custom-event";
import { EventTypes } from "./event-types";

export default class EventBus {
    constructor() { }

    public dispatchEvent(event: Event) {
        document.dispatchEvent(event);
    }

    public registerEventListener(type: EventTypes, listener:any) {
        document.addEventListener(type, listener);
    }
}