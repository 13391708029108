import CustomComponent from "../../base/components/custom_component";
import EventBusEvent from "../../base/events/custom-event";
import EventBus from "../../base/events/event-bus";
import { EventTypes } from "../../base/events/event-types";
import DIContainer from "../../base/helpers/di-container";
import { SearchResultItem } from "../../models/search-result";

export default class SearchTagsFilter extends CustomComponent {
    private readonly showSearchbar: boolean;
    private readonly translations: any;

    private q: string;
    
    private allFilterItems: Array<String>;
    private filterItems: Set<String>;
    private readonly eventBus : EventBus
    public constructor() {
        super();
        this.eventBus = DIContainer.GetInstance().GetSingleton<EventBus>(EventBus);
        this.translations = DIContainer.GetInstance().GetSingleton("search-translations")
        this.allFilterItems = this.getAttribute("all-filter-items") == "" ? [] : this.getAttribute("all-filter-items").split(',') as Array<String>;
        if (this.getAttribute("filter-items") !== '') {
            let re = /%20/gi;
            this.filterItems = new Set(this.getAttribute("filter-items")?.replace(re," ").split(',') as Array<String>)
        } else {
            this.filterItems = new Set();
        }
        this.q = this.getAttribute("q");
        this.showSearchbar = this.getAttribute("enable-searchbar") === "true";
    }


    private fireChange() {
        super.render();
    }

    public BuildHtml(): string {
        return `
           ${(() => {
            if (this.showSearchbar) {
                return `<input id="q-search" class="q-search mb-4 p-4" placeholder="${this.translations.placeholder}"></input>`;
            } else {
                return "";
            }
            })()}
            
            <div class="h-full flex flex-row mb-8 flex-wrap">
                ${(() => {
                let resultHtml = "";
                for (let value of this.allFilterItems) {
                    resultHtml += `<span data-tag class="pill m-1 bg-pill px-5 py-1 rounded-full min-w-15"/>${value}</span>\n`;
                }
                return resultHtml;
            })()
            }
             ${(() => {
                if (this.filterItems.size != 0 || (this.q != null && this.q != "")) {
                    return `<button class="bg-pill-clear pill m-1  px-5 py-1 rounded-full min-w-15" data-reset>${this.translations.clear}</button>`;
            } else {
                return "";
            }
            })()
            }
            </div>
        `
    }

    public setupHTMLMappings(): void {
        document.querySelectorAll("[data-tag]").forEach(tag => {
            if (this.filterItems.has(tag.innerHTML)) {
                tag.classList.add('active');
            }

            tag.addEventListener("click", (event) => {
                if (!this.filterItems.has(tag.innerHTML))
                    this.filterItems.add(tag.innerHTML)
                else
                    this.filterItems.delete(tag.innerHTML);

                this.eventBus.dispatchEvent(new EventBusEvent<String>(EventTypes.FilterOnTags, Array.from(this.filterItems).join(',')))
                tag.classList.toggle('active');
                this.fireChange()

            })
        })

        if (this.showSearchbar) {
            if (this.q) {
                (<HTMLInputElement>document.getElementById('q-search')).value = this.q;
            }
            document.getElementById('q-search').addEventListener('change', event => {
                var query = (<HTMLInputElement>document.getElementById('q-search')).value;
                if (query != undefined && query != null) {
                    this.q = query
                    this.eventBus.dispatchEvent(new EventBusEvent<String>(EventTypes.FilterOnQ, this.q));
                    this.fireChange()
                }
            });
        }


        document.querySelectorAll("[data-reset]").forEach(reset => {
            reset.addEventListener("click", (event) => {
                this.filterItems.clear();
                if (this.showSearchbar) {
                    (<HTMLInputElement>document.getElementById('q-search')).value = "";
                    this.q = "";
                }

                document.querySelectorAll("[data-tag]").forEach(tag => {
                    tag.classList.remove('active');
                })

                this.eventBus.dispatchEvent(new EventBusEvent<String>(EventTypes.ClearQuery, ""))
                this.fireChange();
            })
        })
    }

}