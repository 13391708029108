﻿import ComponentRegistrar from "./base/components/component_registrar";
import EventBus from "./base/events/event-bus";
import DIContainer from "./base/helpers/di-container";
import HTTPJSONClientManager from "./base/helpers/http-json-client-manager";
import Pagination from "./components/search/pagination";
import Search from "./components/search/search";
import SearchResults from "./components/search/search-results";
import SearchCard from "./components/search/search-card";
import SearchTagsFilter from "./components/search/search-tags-filter";
import Loader from "./components/search/loader";


var eventBus = new EventBus();
DIContainer.GetInstance().AddSingleton(eventBus);
DIContainer.GetInstance().AddSingleton(new HTTPJSONClientManager());

var registrar = new ComponentRegistrar();
registrar.Register("sj-search", Search);
registrar.Register("sj-search-results", SearchResults);
registrar.Register("sj-search-card", SearchCard);
registrar.Register("sj-search-tags-filter", SearchTagsFilter);
registrar.Register("sj-pagination", Pagination);
registrar.Register("sj-loader", Loader);


registrar.Start();