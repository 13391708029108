﻿import CustomComponent from "../../base/components/custom_component";
import EventBusEvent from "../../base/events/custom-event";
import EventBus from "../../base/events/event-bus";
import { EventTypes } from "../../base/events/event-types";
import DIContainer from "../../base/helpers/di-container";
import HTTPJSONClientManager from "../../base/helpers/http-json-client-manager";
import { SearchResult } from "../../models/search-result";

export default class Search extends CustomComponent {
    //Properties & Singletons
    private readonly allFilters: Array<string>;
    private readonly parentId: string;
    private readonly enableSearchbar: boolean;
    private readonly eventBus: EventBus

    //
    private filters: string = "";
    private q: string = "";
    private currentPage: number = 1;
    private pageSize: number = 5;

    public constructor() {
        super();
        this.eventBus = DIContainer.GetInstance().GetSingleton<EventBus>(EventBus);
        if (this.getAttribute("translations") != null) {
            DIContainer.GetInstance().AddSingleton("search-translations", JSON.parse((this.getAttribute("translations"))));
        }
        this.allFilters = this.getAttribute("filters").split(',');
        this.parentId = this.getAttribute("parentId");
        if (Number.parseInt(this.getAttribute("page-size")))
            this.pageSize = Number.parseInt(this.getAttribute("page-size"));
        this.enableSearchbar = this.getAttribute("enable-searchbar") == "True" ? true : false;
        this.getQueryFromUrl();


    }

    private fireChange() {
        super.render();
    }
    private getQueryFromUrl() {
        const currentUrl = window.location.href;
        const regex = new RegExp(/[?&](q|tags|page)=([^&]*)/g);
        const params = {};
        let match;

        while ((match = regex.exec(currentUrl)) !== null) {
            const [, key, value] = match;
            params[key] = value;
        }
        console.log(params)
        if (params['tags'])
            this.filters = params['tags'];
        if (params['q']) {
            this.q = params['q'].replace('%20',' ');
        }
        if (params['page']) {
            this.currentPage = params['page'];
        }
    }


    public BuildHtml(): string {
        return `
            <div id="search-container" class="mx-auto w-full px-3 md:px-10 xl:px-12">
                <sj-search-tags-filter class="pb-4 block" enable-searchbar="${this.enableSearchbar}"  q="${this.q}" filter-items="${this.filters}" all-filter-items="${this.allFilters.join(',').replace(/\'/g, "&rsquo;")}"></sj-search-tags-filter>
                <sj-search-results current-page=${this.currentPage} q="${this.q}" filters="${this.filters}" parentId="${this.parentId}" page-size="${this.pageSize}"></sj-search-results>
                <sj-pagination current-page=${this.currentPage} page-size="${this.pageSize}"></sj-pagination>
            </div>
        `
    }



    public setupHTMLMappings(): void {
    }

}