﻿import CustomComponent from "../../base/components/custom_component";
import EventBusEvent from "../../base/events/custom-event";
import EventBus from "../../base/events/event-bus";
import { EventTypes } from "../../base/events/event-types";
import DIContainer from "../../base/helpers/di-container";

export default class Pagination extends CustomComponent {

    private currentPage: number = 1;
    private totalPages: number = 1;
    private readonly eventBus: EventBus

    public constructor() {
        super();
        if (Number.parseInt(this.getAttribute("current-page")))
            this.currentPage = Number.parseInt(this.getAttribute("current-page"));
        this.eventBus = DIContainer.GetInstance().GetSingleton<EventBus>(EventBus);
        this.eventBus.registerEventListener(EventTypes.TotalPages, this.changeTotalPages.bind(this));
        this.eventBus.registerEventListener(EventTypes.FilterOnTags, this.handleFilterChange.bind(this));
        this.eventBus.registerEventListener(EventTypes.FilterOnQ, this.handleFilterChange.bind(this));
        this.eventBus.registerEventListener(EventTypes.ClearQuery, this.handleFilterChange.bind(this));
    }

    public async connectedCallback() {
       
    }
    private handleFilterChange(event: EventBusEvent<any>) {
        this.changeCurrentPage(1);
        this.fireChange();
    }
    private changeTotalPages(event: EventBusEvent<number>) {
        this.totalPages = event.getData();
        this.fireChange();
    }


    public nextPage() : void {
        if (this.currentPage < this.totalPages) {
            this.changeCurrentPage(this.currentPage +1);
            this.fireChange();
        }
    }

    public prevPage() : void {
        if (this.currentPage > 1) {
            this.changeCurrentPage(this.currentPage - 1);
            this.fireChange();
        }
    }

    public goTo(page: number): void {
        if (page > 0 && page <= this.totalPages) {
            this.changeCurrentPage(page);
            this.fireChange();
        }
    }

    public changeCurrentPage(number: number) : void {
        this.currentPage = number;
        this.eventBus.dispatchEvent(new EventBusEvent<number>(EventTypes.ChangePage, this.currentPage))
    }

    public constructPageNumber() {
        const maxPages = 2;
        let start = Math.floor(Math.max(this.currentPage - 1 - maxPages, 1));
        let end = Math.floor(Math.min(maxPages + this.currentPage, this.totalPages));
        let pages = [];
        for (var i = start; i <= end; i++) {
            pages.push(i);
        }
        return pages;
    }

    private fireChange() : void {
        super.render();
    }

    public BuildHtml(): string {
        return `
            <div class="w-fit mt-8 mx-auto pagination">
                ${(() => {
                    let resultHtml = "";
                    if (this.totalPages != 0) {
                        resultHtml +=
                            `
                            <button data-start class="${this.currentPage == 1 ? "pagination-control-inactive" : ""}">
                                <i class="fa-light fa-chevron-double-left"></i>
                            </button>
                            <button data-prev class="pagination-control prev ${this.currentPage == 1 ? "pagination-control-inactive" : ""}">
                                <i class="fa-light fa-chevron-left"></i>
                            </button>`
                        const pageNumbers = this.constructPageNumber()
                        for (const [index, value] of pageNumbers.entries()) {
                            resultHtml += `<button data-go-to class="${index != pageNumbers.length - 1 ? "border-right" : ""} page-number ${this.currentPage == value ? "active" : ""}">${value}</button>\n`;
                        }
                        resultHtml +=
                            `<button data-next class="pagination-control next ${this.currentPage == this.totalPages ? "pagination-control-inactive" : ""}">
                                <i class="fa-light fa-chevron-right"></i>
                            </button>
                            <button data-end class="${this.currentPage == this.totalPages ? "pagination-control-inactive" : ""}">
                                <i class="fa-light fa-chevron-double-right"></i>
                            </button>
                            `
                    }
                    return resultHtml;
                })()
            }
            </div>
        `
    }

    public setupHTMLMappings(): void {
        document.querySelectorAll("[data-next]").forEach(next => {
            next.addEventListener("click", () => { this.nextPage(); })
        })
        document.querySelectorAll("[data-prev]").forEach(next => {
            next.addEventListener("click", () => { this.prevPage(); })
        })
        document.querySelectorAll("[data-start]").forEach(next => {
            next.addEventListener("click", () => { this.goTo(1); })
        })
        document.querySelectorAll("[data-end]").forEach(next => {
            console.log(this.totalPages);
            next.addEventListener("click", () => { this.goTo(this.totalPages); })
        })

        document.querySelectorAll("[data-go-to]").forEach(pageCounter => {
            pageCounter.addEventListener("click", () => { this.goTo(Number.parseInt(pageCounter.innerHTML)); })
        })
    }

}