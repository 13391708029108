﻿import { EventTypes } from "./event-types";

    export default class EventBusEvent<T> extends Event {
        private _type: EventTypes;
        private _data: T;

        constructor(type: EventTypes, data: T) {
            super(type);
            this._type = type;
            this._data = data;
        }

        public getData(): T {
            return this._data;
        }
    }