﻿export default abstract class CustomComponent extends HTMLElement {
    public abstract BuildHtml(): string
    public abstract setupHTMLMappings(): void

    constructor() {
        super();
    }

    //add the html to shadow root
    connectedCallback() {
        this.render();
    }

    render() {
        this.innerHTML = this.BuildHtml();
        this.setupHTMLMappings();
    }
}