﻿import { SearchResult } from "../../models/search-result";
import TestApiModel from "../../models/test-api-model";
import HTTPJSONClient from "./http_json_client";

export default class HTTPJSONClientManager{
    private readonly apiClient: HTTPJSONClient;

    constructor()
    {
        this.apiClient = new HTTPJSONClient("/api");
    }

    public async search(query: string, tags: string, parentId = "", skip: number, take: number): Promise<SearchResult> {
        try {
            const response: Response = await this.apiClient.Get("/search/ontags" + `?&q=${query}&tags=${tags}&parentId=${parentId}&skip=${skip}&take=${take}`);
            const data: Promise<SearchResult> =  response.json();
            return data;
        }
        catch (e: any) {
            console.error(e);
        }
    }

}