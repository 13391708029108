﻿export default class HTTPJSONClient {
    private baseUrl: string;
    private baseOptions = {
        method: "get",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: undefined
    }

    constructor(base: string) {
        this.baseUrl = base;
    }

    public Get(endpoint: string = "", query: string = ""): Promise<Response> {
        this.baseOptions.method = "get";
        var urlToCall = this.baseUrl + endpoint + query;
        return fetch(urlToCall, this.baseOptions)
            .then(response => {
                return Promise.resolve(response)
        }).catch((error) => {
            console.error(error);
            return Promise.reject(error);
        })
    }

    public Post(input: any, endpoint: string = ""): Promise<Response> {
        this.baseOptions.method = "post";
        var toPost = JSON.stringify(input);
        this.baseOptions.body = toPost;
        var urlToCall = this.baseUrl + endpoint;
        return fetch(urlToCall, this.baseOptions).then(response => {
            return Promise.resolve(response)
        }).catch((error) => {
            console.error(error);
            return Promise.reject(error);
        })
    }
}