import CustomComponent from "../../base/components/custom_component";
import HTTPJSONClientManager from "../../base/helpers/http-json-client-manager";
import { SearchResult, SearchResultItem } from "../../models/search-result";
import EventBus from "../../base/events/event-bus";
import EventBusEvent from "../../base/events/custom-event";
import { EventTypes } from "../../base/events/event-types";
import DIContainer from "../../base/helpers/di-container";

export default class SearchResults extends CustomComponent {
    //Properties & Singletons
    private readonly httpClient: HTTPJSONClientManager;
    private readonly eventBus: EventBus
    private readonly parentId: string;
    //
    private result: SearchResult;
    private filters: string = "";
    private q: string = "";
    private currentPage: number = 1;
    private pageSize: number = 5;
    private loading: boolean = true;
    public constructor() {
        super();
        this.changeLoading(true);
        this.httpClient = DIContainer.GetInstance().GetSingleton<HTTPJSONClientManager>(HTTPJSONClientManager);
        this.eventBus = DIContainer.GetInstance().GetSingleton<EventBus>(EventBus);

        this.filters = this.getAttribute("filters");
        this.q = this.getAttribute("q");
        if (Number.parseInt(this.getAttribute("current-page")))
            this.currentPage = Number.parseInt(this.getAttribute("current-page"));
        if (Number.parseInt(this.getAttribute("parentId")))
            this.parentId = this.getAttribute("parentId");
        if (Number.parseInt(this.getAttribute("page-size")))
            this.pageSize = Number.parseInt(this.getAttribute("page-size"));
    }

    public async search() {
        let skip = (this.currentPage - 1) * this.pageSize;
        let take = this.pageSize;
        console.log(this.pageSize);
        console.log(this.currentPage)
        this.setUrlFromQuery();
        let result = await this.httpClient.search(this.q, this.filters, this.parentId ? this.parentId : "", skip, take) as SearchResult;
        this.changeResult(result);
    }

    public setUrlFromQuery() {
        let urlQuery = "?"
        if (this.q) {
            
            urlQuery += "q=" + this.q;

        }
        if (this.filters) {
            urlQuery += urlQuery.length == 1 ? "" : "&";
            urlQuery += "tags=" + this.filters;

        }

        urlQuery += urlQuery.length == 1 ? "" : "&";
        urlQuery += "page=" + this.currentPage;

        
        window.history.pushState({}, '', urlQuery);
    }

    private fireChange(): void {
        super.render();
    }

    public async connectedCallback() {
        this.eventBus.registerEventListener(EventTypes.FilterOnTags, this.handleFilterChange.bind(this));
        this.eventBus.registerEventListener(EventTypes.FilterOnQ, this.handleQChange.bind(this));
        this.eventBus.registerEventListener(EventTypes.ClearQuery, this.clearQuery.bind(this));
        this.eventBus.registerEventListener(EventTypes.ChangePage, this.handleChangePage.bind(this));
        this.search();

    }

    private changeResult(newResult: SearchResult) {
        this.result = newResult
        this.eventBus.dispatchEvent(new EventBusEvent<number>(EventTypes.TotalPages, this.result.pagination.totalPages))
        this.changeLoading(false);
        this.fireChange();
    }

    private changeLoading(loading: boolean) {
        this.loading = loading;
        if (this.loading == true) {
            document.getElementsByClassName("menu-margin").item(0)?.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
        }
        this.fireChange();
    }

    public handleFilterChange(event: EventBusEvent<string>) {
        this.filters = event.getData();
        this.changeLoading(true);
        this.search();
    }
    public handleQChange(event: EventBusEvent<string>) {
        this.q = event.getData();
        this.changeLoading(true);
        this.search();
    }

    public clearQuery(event: EventBusEvent<string>) {
        this.filters = "",
        this.q = "";
        this.changeLoading(true);
        this.search();
    }
    
    public handleChangePage(event: EventBusEvent<number>) {
        console.log(event.getData())
        this.currentPage = event.getData();
        this.changeLoading(true);
        this.search();
    }

    public BuildHtml(): string {
        return `   
            ${(() => {
                if (!this.loading) {

                    let resultHtml = `<div class="search flex flex-wrap">`;
                    if (this.result && this.result.results[0]) {
                    //    resultHtml = `<sj-search-card class="search-card length-2" length="2"" item='${JSON.stringify(this.result.results[0]).replace(/\'/g, " & rsquo;")}'/></sj-search-card>\n`;
                    }
                    if (this.result) {
                        
                        for (const [index, value] of this.result.results.entries() ) {
                            resultHtml += `<sj-search-card class="search-card ${index == 0 && value.topStory == 1 ? "length-2" : ""}" item='${JSON.stringify(value).replace(/\'/g, "&rsquo;")}'/></sj-search-card>\n`;
                        }
                    }
                    if (this.result.results.length == 0) {
                        //Verlaten
                        resultHtml += `<div>No results</div>`
                    }
                    resultHtml += `</div>`;
                    return resultHtml;
                } else {
                    return ` <sj-loader></sj-loader>`;
                }
                })()
            }`
    }

    public setupHTMLMappings(): void {

    }

}